import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
	<path d="M2114 3542 c-5 -4 -55 -115 -111 -247 -56 -132 -204 -478 -329 -770
-124 -291 -228 -536 -231 -542 -4 -10 42 -13 210 -13 l215 0 30 73 c16 39 50
120 75 180 l46 107 -95 110 c-51 60 -94 111 -94 114 0 3 64 7 142 8 l141 3
113 279 c63 153 116 281 119 284 7 7 6 -162 -2 -416 l-6 -212 -174 0 c-95 0
-173 -2 -173 -5 0 -3 74 -89 165 -191 l165 -185 0 -74 0 -75 140 0 c140 0 140
0 120 -20 -34 -34 -19 -63 30 -58 40 4 60 30 43 57 -6 10 -8 22 -3 27 4 5 29
104 54 219 l46 210 6 400 7 400 -135 170 -135 170 -185 2 c-102 0 -189 -2
-194 -5z"/>
<path d="M2590 3531 c0 -10 100 -135 111 -139 5 -2 7 -7 5 -11 -3 -4 15 -28
39 -54 23 -26 41 -47 38 -47 -3 0 0 -7 7 -16 10 -12 33 -15 99 -14 47 1 91 1
97 1 9 -1 9 -12 3 -38 -14 -58 -48 -218 -70 -323 -11 -52 -22 -103 -25 -114
-5 -14 -3 -16 7 -11 9 6 8 1 -2 -11 -9 -10 -19 -32 -22 -49 -3 -16 -16 -75
-27 -130 -12 -55 -23 -109 -25 -120 -1 -11 -5 -27 -8 -35 -3 -8 -15 -60 -27
-115 -26 -123 -58 -268 -66 -301 l-6 -24 196 0 c168 0 196 2 196 15 0 17 16
20 25 4 4 -6 14 -8 22 -5 8 4 11 1 8 -5 -11 -16 28 -6 42 11 9 12 14 13 24 3
7 -7 18 -13 23 -13 6 0 0 7 -12 16 -12 8 -26 13 -31 10 -10 -6 -25 24 -16 32
3 3 5 1 5 -5 0 -5 6 -10 13 -10 6 0 21 -4 32 -7 11 -4 3 2 -18 14 -31 17 -41
19 -53 9 -12 -10 -17 -10 -27 0 -9 10 -8 11 6 6 23 -9 22 0 -4 24 -36 33 -13
44 35 16 40 -23 79 -36 46 -15 -17 11 -1 13 25 4 12 -5 13 -9 5 -14 -8 -5 -7
-9 3 -13 10 -4 14 0 13 11 -2 9 -17 22 -34 27 -17 6 -33 13 -36 16 -8 7 50 2
66 -6 7 -3 4 0 -6 9 -10 8 -23 17 -28 18 -5 2 -10 13 -11 25 0 13 -7 25 -15
28 -8 3 -10 0 -6 -8 4 -6 -8 1 -27 18 -19 16 -38 34 -44 38 -5 5 -1 5 10 1 18
-7 18 -6 6 9 -7 9 -17 15 -21 12 -4 -2 -22 11 -40 30 -18 19 -38 31 -43 28 -6
-4 -8 0 -4 9 3 9 -1 28 -9 44 -19 35 -13 50 23 58 15 4 35 13 43 20 8 7 34 18
58 26 23 7 47 19 53 26 6 7 26 86 45 176 19 90 42 192 50 226 8 35 13 70 10
78 -3 8 -2 12 4 9 5 -3 9 3 8 12 0 10 12 67 26 127 14 60 23 113 20 118 -3 4
22 8 56 8 l61 0 -6 -32 c-4 -18 -8 -44 -9 -58 -2 -14 -7 -33 -11 -42 -7 -17 6
-18 190 -18 192 0 196 0 190 20 -3 11 -3 20 2 20 9 0 15 28 16 72 0 16 4 25
10 21 6 -3 8 6 5 25 -2 17 -1 29 3 26 7 -4 15 33 24 106 1 8 6 23 10 32 4 10
8 35 8 57 1 21 6 37 11 34 5 -3 7 0 4 8 -4 12 -113 14 -676 16 -370 2 -672 -1
-672 -6z m44 -37 c3 -8 2 -12 -4 -9 -6 3 -10 10 -10 16 0 14 7 11 14 -7z m48
-29 c3 3 11 -3 19 -12 13 -16 12 -17 -3 -4 -10 7 -18 10 -18 6 0 -5 8 -17 18
-28 9 -11 12 -17 5 -13 -7 3 -22 21 -35 39 -12 18 -15 26 -7 20 9 -7 18 -11
21 -8z m111 -82 c-3 -7 -6 -13 -8 -13 -2 0 -5 6 -8 13 -3 8 1 14 8 14 7 0 11
-6 8 -14z m-27 -51 c-6 -5 -36 23 -36 34 0 4 9 -1 20 -11 11 -10 18 -20 16
-23z m241 -144 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m20 -50
c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m300 0 c-3 -8 -6 -5 -6
6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m180 -14 c-3 -3 -12 -4 -19 -1 -8 3 -5 6
6 6 11 1 17 -2 13 -5z m-553 -280 c3 -8 2 -12 -4 -9 -6 3 -10 10 -10 16 0 14
7 11 14 -7z m283 -146 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z
m-380 -170 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-75 -350
c-8 -8 -9 -4 -5 13 4 13 8 18 11 10 2 -7 -1 -18 -6 -23z m413 -168 c8 -13 -5
-13 -25 0 -13 8 -13 10 2 10 9 0 20 -4 23 -10z"/>
<path d="M3542 2726 c-1 -10 -30 -45 -63 -77 -34 -32 -57 -59 -53 -59 5 0 2
-5 -6 -11 -12 -9 -12 -10 3 -5 12 4 17 2 14 -6 -2 -7 -12 -12 -21 -11 -16 1
-16 0 1 -17 10 -9 20 -14 23 -11 3 4 17 14 32 24 44 28 80 98 75 149 -2 24 -4
35 -5 24z m-81 -130 c-6 -7 -8 -16 -4 -19 3 -4 4 -7 1 -7 -3 0 -11 7 -18 16
-11 12 -11 15 1 12 8 -2 13 3 11 10 -1 8 2 11 8 7 8 -5 8 -11 1 -19z m-1 -40
c0 -2 -7 -7 -16 -10 -8 -3 -12 -2 -9 4 6 10 25 14 25 6z"/>
<path d="M3570 2647 c0 -24 -44 -91 -81 -125 -32 -28 -61 -71 -40 -58 6 4 11
3 11 -3 0 -5 -6 -12 -12 -14 -8 -3 -7 -6 4 -6 9 -1 20 4 23 10 4 6 14 8 22 5
16 -6 60 39 80 83 15 32 16 91 3 111 -8 13 -10 12 -10 -3z m-89 -164 c-12 -20
-14 -14 -5 12 4 9 9 14 11 11 3 -2 0 -13 -6 -23z"/>
<path d="M3400 2509 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M3460 2427 c-14 -12 -21 -19 -15 -15 7 4 14 6 17 3 3 -3 13 4 23 15
25 27 6 25 -25 -3z"/>
<path d="M3165 2360 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"/>
<path d="M3108 2338 c-16 -5 -28 -14 -28 -19 0 -8 54 14 69 27 7 7 -13 3 -41
-8z"/>
<path d="M3240 2146 c0 -2 11 -6 25 -8 13 -3 22 -1 19 3 -5 9 -44 13 -44 5z"/>
<path d="M3205 1980 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
<path d="M3184 1973 c4 -8 1 -13 -8 -13 -19 0 -38 -34 -30 -54 8 -21 67 -21
85 0 10 13 10 19 0 36 -10 15 -10 18 0 13 8 -6 10 -4 5 4 -5 7 -14 10 -21 7
-7 -2 -18 1 -25 7 -10 9 -11 9 -6 0z"/>
<path d="M1793 1863 c-13 -9 -95 -292 -87 -305 3 -4 23 -8 46 -8 31 0 41 4 45
20 4 16 14 20 44 20 24 0 41 -6 47 -16 6 -12 17 -14 43 -9 64 12 64 10 11 161
l-48 139 -45 3 c-24 1 -49 -1 -56 -5z m60 -173 c4 -14 0 -20 -13 -20 -13 0
-16 6 -12 25 5 28 17 26 25 -5z"/>
<path d="M2435 1865 c-5 -2 -45 -6 -87 -10 l-78 -7 0 -44 0 -44 40 0 40 0 0
-100 c0 -112 3 -117 65 -106 l35 7 0 103 c0 113 2 115 68 116 19 0 22 5 22 45
l0 45 -47 -1 c-27 0 -52 -2 -58 -4z"/>
<path d="M2068 1845 c-49 -28 -71 -92 -46 -138 18 -35 40 -46 91 -47 34 0 47
-4 44 -12 -2 -9 -26 -13 -68 -13 l-63 -1 -7 -34 c-9 -40 -2 -47 58 -56 100
-15 173 30 173 106 0 58 -33 90 -94 90 -46 0 -66 12 -44 27 7 4 35 8 62 8 46
0 50 2 57 30 5 17 4 34 -1 39 -17 17 -132 18 -162 1z"/>
<path d="M2560 1704 l0 -157 42 7 c23 4 44 9 47 12 10 9 13 126 6 209 l-7 85
-44 0 -44 0 0 -156z"/>
<path d="M2676 1752 c-3 -60 -3 -129 0 -154 l7 -47 71 6 c119 9 131 13 134 56
l3 37 -60 0 -61 0 0 105 0 105 -43 0 -44 0 -7 -108z"/>
<path d="M2910 1704 l0 -156 48 6 c26 3 73 8 105 11 l57 6 0 40 0 39 -60 0
-60 0 0 105 0 105 -45 0 -45 0 0 -156z"/>
<path d="M3140 1704 l0 -157 42 7 c22 4 45 9 50 12 9 6 11 180 2 248 l-7 46
-43 0 -44 0 0 -156z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
